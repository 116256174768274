
import { PropType } from 'vue'
import { CourseSeries } from 'momai'

export default {
  props: {
    productList: {
      type: Array as PropType<CourseSeries[]>,
      default: () => []
    },
    columnCum: {
      type: Number,
      default: 3
    }
  },
  setup: () => {
    return {
    }
  }
}
